export const works = [
  {
    title: "Binsahl Store",
    describe: "E-commerece Store for deffint items.",
    image: "site-1.png",
    url: "https://binsahl.com",
  },
  {
    title: "Bakery recipe",
    describe:
      "Bakery Recipes is a community-driven platform dedicated to sharing and discovering delicious bakery recipes.",
    image: "site-2.png",
    url: "https://bakery-recipes.vercel.app/",
  },
  {
    title: "Maha Binsahl",
    describe: "Graphic Designer Portfolio.",
    image: "site-3.png",
    url: "https://www.mahabnsahl.com/",
  },
  {
    title: "Yadawi Store",
    describe:
      "E-commerece Store for hand made products for all diffrent cultures.",
    image: "site-4.png",
    url: "https://yadawystore.netlify.app/",
  },
];

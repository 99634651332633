import React from 'react'
import AboutHero from './AboutHero'
import SkillsSection from './SkillsSection'

import styles from './about.module.css'
import FollowSection from './FollowSection'

const About = () => {
  return (
    <div className={styles.about}>
      <AboutHero />
      <SkillsSection />
      <FollowSection />
    </div>
  )
}

export default About

import React from "react";
import { Link } from "react-router-dom";

import styles from "./menu-aside.module.css";
import classNames from "classnames";

interface MenubarProps {
  menu: boolean;
  setMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Menubar({ menu, setMenu }: MenubarProps) {
  return (
    <section className={classNames(styles.menubar, { [styles.active]: menu })}>
      <header className={styles.menubarHeader}>
        <i
          className={classNames(styles.closeIcon, "bx bx-x")}
          onClick={() => setMenu(false)}
        ></i>
      </header>

      <ul className={styles.menubarLinks}>
        <li className={styles.menubarLink} onClick={() => setMenu(false)}>
          <Link className={styles.link} to="/">
            Home
          </Link>
        </li>
        <li className={styles.menubarLink} onClick={() => setMenu(false)}>
          <a className={styles.link} href="#contact">
            Contact
          </a>
        </li>
        <li className={styles.menubarLink} onClick={() => setMenu(false)}>
          <Link className={styles.link} to="/portfolio">
            Portfolio
          </Link>
        </li>
        <li className={styles.menubarLink} onClick={() => setMenu(false)}>
          <Link className={styles.link} to="/about">
            About
          </Link>
        </li>
      </ul>
    </section>
  );
}

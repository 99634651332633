import React from "react";

import styles from "./home.module.css";
import { works } from "../../data/work";
import Work from "../work/Work";

const WorkSection = () => {
  return (
    <section className={styles.work}>
      <h1 className={styles.workTitle} data-aos="fade-up">
        My Masterpieces
      </h1>
      <div
        className={styles.work__content}
        data-aos-delay="300"
        data-aos="fade-up"
      >
        {works.slice(0, 3).map((work) => (
          <Work
            key={work.title}
            description={work.describe}
            title={work.title}
            image={require(`../../assets/images/work/${work.image}`)}
            link={work.url}
          />
        ))}
      </div>
    </section>
  );
};

export default WorkSection;

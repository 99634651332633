/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";

import myImage from "../../assets/images/my-picture-transparent.png";
import githubIcon from "../../assets/images/social-icons/github.svg";
import linkedinIcon from "../../assets/images/social-icons/linkedin.svg";
import instagramIcon from "../../assets/images/social-icons/instagram.svg";
import twitterIcon from "../../assets/images/social-icons/twitter.svg";
import emailIcon from "../../assets/images/social-icons/email.svg";
import art1 from "../../assets/images/art1.svg";
import art2 from "../../assets/images/art2.svg";

import styles from "./home.module.css";
import Button from "../button/Button";
import { Link } from "react-router-dom";

export const social = [
  {
    name: "Github",
    link: "https://github.com/mohammedBinSahl6",
    icon: githubIcon,
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/mohammed-bin-sahl-a79a5023b/",
    icon: linkedinIcon,
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/mo.official_6/",
    icon: instagramIcon,
  },
  {
    name: "Twitter",
    link: "https://x.com/mo_binsahl",
    icon: twitterIcon,
  },
  {
    name: "Email",
    link: "mailto:mohammed.dbs.660@gmail.com",
    icon: emailIcon,
  },
];

const AboutSection = () => {
  return (
    <section className={styles.about}>
      <div className={styles.about__content}>
        <div className={styles.about__image} data-aos="fade-right">
          <img src={myImage} alt="my-picture"  className={styles.myPic} data-aos="fade-up" data-aos-delay="400"/> 
        </div>
        <div className={styles.about__info}>
          <h1 className={styles.aboutName}>Mohammed Binsahl</h1>
          <h2 className={styles.aboutTitle}>Web Developer</h2>
        </div>
      </div>

      <span className={styles.about__social}>
        {social.map((item, index) => (
          <a href={item.link} key={index} target="_blank" rel="noreferrer">
            <img src={item.icon} alt={item.name} />
          </a>
        ))}
      </span>
      <Link to="/about" className={styles.about__btn}>
        <Button text="Watch more" size="large" />
      </Link>

      <img
        src={art1}
        alt="art1"
        className={styles.about__art1}
        data-aos="fade-down"
        data-aos-duration="800"
      />
      <img
        src={art2}
        alt="art2"
        className={styles.about__art2}
        data-aos="fade-down"
        data-aos-duration="800"
      />
    </section>
  );
};

export default AboutSection;

import React from "react";
import classNames from "classnames";

import styles from "./button.module.css";

interface ButtonProps {
  text: string;
  type?: "button" | "submit" | "reset";
  variant?: "primary" | "secondary";
  size?: "small" | "medium" | "large";
  disabled?: boolean;
  isFullWidth?: boolean;
  onClick?: () => void;
}

const Button = ({
  text,
  type = "button",
  variant="primary",
  size="medium",
  disabled,
  isFullWidth,
  onClick,
}: ButtonProps) => {
  return (
    <button
      className={classNames(styles.button, {
        [styles[`button--${variant}`]]: variant,
        [styles[`button--${size}`]]: size,
        [styles.button__fullWidth]: isFullWidth,
      })}
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default Button;

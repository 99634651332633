import React from "react";

import styles from "./home.module.css";

import art1 from "../../assets/images/art1.svg";
import art2 from "../../assets/images/art2.svg";
import logo from "../../assets/images/white-logo.svg";

const Particle = ({
  top,
  left,
  delay = 0,
}: {
  top: number;
  left: number;
  delay?: number;
}): React.JSX.Element => {
  return (
    <span
      className={styles.particle}
      style={{ top, left, position: "absolute", animationDelay: `${delay}s` }}
    />
  );
};

const HeroSection = (): React.JSX.Element => {
  return (
    <section className={styles.hero}>
      <h1 className={styles.heading}>Welcome to My World!</h1>
      <h3 className={styles.jobTitle}>Web Developer</h3>

      <img src={art1} alt="art1" className={styles.art1} />
      <img src={art2} alt="art2" className={styles.art2} />
      <img src={logo} alt="logo" className={styles.logo} />

      <Particle top={200} left={300} delay={0.4}/>
      <Particle top={250} left={400} delay={0.6} />
      <Particle top={600} left={600} delay={0.2}/>
      <Particle top={100} left={100} delay={0.3}/>
      <Particle top={150} left={800} delay={0.1}/>
      <Particle top={400} left={900} delay={0.8}/>
      <Particle top={300} left={700} delay={0.7}/>
      <Particle top={700} left={300} delay={0.2}/>
      <Particle top={700} left={1000} delay={0.5}/>
      <Particle top={100} left={1300} delay={0.4}/>
    </section>
  );
};

export default HeroSection;

import React from "react";

import styles from "./work.module.css";

export default function Work({ title, description, image, link }) {
  return (
    <div
      className={styles.workCard}
      onClick={() => (window.location.href = link)}
    >
      <img className={styles.workCard__image} src={image} alt="work" />
      <div className={styles.workCard__content}>
        <p className={styles.workCard__title}>{title}</p>
        <p className={styles.workCard__description}>{description}</p>
      </div>
    </div>
  );
}

import React from 'react'

import styles from './skill-tag.module.css'

const SkillTag = ({label}: {label: string}) => {
  return (
    <div className={styles.skillTag}>
      {label}
    </div>
  )
}

export default SkillTag

import Work from "../work/Work";
import PortfolioHero from "./PortfolioHero";

import { works } from "../../data/work";

import styles from "./portfolio.module.css";

export default function Portfolio() {
  return (
    <div className={styles.portfolio}>
      <PortfolioHero />
      <div className={styles.portfolioBody}>
        {works.map((work) => (
          <Work
            key={work.id}
            description={work.describe}
            image={require(`../../assets/images/work/${work.image}`)}
            link={work.url}
            title={work.title}
          />
        ))}
      </div>
    </div>
  );
}

import HeroSection from "./HeroSection";
import AboutSection from "./AboutSection";
import WorkSection from "./WorkSection";
import styles from "./home.module.css";
import ContactSection from "./ContactSection";

export default function Home() {
  return (
    <div className={styles.home}>
      <HeroSection />
      <AboutSection />
      <WorkSection />
      <ContactSection />
    </div>
  );
}

import { Link } from "react-router-dom";
import classNames from "classnames";

import styles from "./navbar.module.css";
import logo from "../../assets/images/logo.svg";
import whiteLogo from "../../assets/images/white-logo.svg";
import { navLinks } from "./navLinks";

export default function Navbar({ navbar, setMenu }) {
  return (
    <nav
      className={classNames(styles.navbar, {
        [styles.navbar__light]: navbar,
      })}
    >
      <div className={styles.navbar__content}>
        <Link to="/">
          <img
            className={styles.logo}
            src={navbar ? logo : whiteLogo}
            alt="logo"
          />
        </Link>
        <ul className={styles.nav__links}>
          {navLinks.map((item) => (
            <li key={item.name}>
              <Link to={item.link} className={styles.link}>
                {item.name}
              </Link>
            </li>
          ))}
        </ul>
        <i
          className={classNames(styles.menu, 'bx bx-menu')}
          onClick={() => setMenu(true)}
        />
      </div>
    </nav>
  );
}

import { Route, Routes, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Aos from "aos";

import Home from "./components/home/Home";
import Navbar from "./components/navbar/Navbar";
import Portfolio from "./components/portfolio/Portfolio";
import About from "./components/about/About";
import Menubar from "./components/menu-aside/MenuAside";

import "./App.css";

import "aos/dist/aos.css";
Aos.init();

function App() {
  const [navbar, setNavbar] = useState(false);
  const [menu, setMenu] = useState(false);
  const location = useLocation();
  const changeNav = () => {
    if (
      window.scrollY >= 80 ||
      location.pathname.toLocaleLowerCase().includes("about") ||
      location.pathname.toLocaleLowerCase().includes("portfolio")
    ) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeNav);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="App">
      <Navbar navbar={navbar} setNavbar={setNavbar} setMenu={setMenu} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/about" element={<About setNavbar={setNavbar} />} />
      </Routes>
      <Menubar menu={menu} setMenu={setMenu} />
    </div>
  );
}

export default App;
